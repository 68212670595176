<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="shopId"
                    :label="$t('pageKey142', '商户')"
                    v-if="userData.appId === 1"
                >
                    <RemoteSelect
                        filterable
                        remote="/sp/shop/select"
                        placeholder="请输入"
                        :props="{id:'id',name:'shopName'}"
                        v-model="queryFormModel.shopId"
                    />
                </el-form-item>
                <el-form-item
                    prop="storeName"
                    :label="$t('pageKey698', '门店名称')"
                >
                    <el-input
                        v-model="queryFormModel.storeName"
                        :placeholder="$t('pageKey897', '请输入门店名称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="templateName"
                    :label="$t('pageKey168', '模板名称')"
                >
                    <el-input
                        v-model="queryFormModel.templateName"
                        :placeholder="$t('pageKey169', '请输入模板名称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    :label="$t('pageKey12', '是否可用')"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        :placeholder="$t('pageKey101', '请选择')"
                    >
                        <el-option
                            :label="$t('pageKey6', '全部')"
                            value=""
                        />
                        <el-option
                            key="1"
                            :label="$t('pageKey209', '是')"
                            value="1"
                        />
                        <el-option
                            key="2"
                            :label="$t('pageKey210', '否')"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="isDefault"
                    :label="$t('pageKey170', '是否默认')"
                >
                    <el-select
                        v-model="queryFormModel.isDefault"
                        :placeholder="$t('pageKey101', '请选择')"
                    >
                        <el-option
                            :label="$t('pageKey6', '全部')"
                            value=""
                        />
                        <el-option
                            key="1"
                            :label="$t('pageKey209', '是')"
                            value="1"
                        />
                        <el-option
                            key="2"
                            :label="$t('pageKey210', '否')"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/pfsp/spmg/shopPaymentConfig')"
                    >
                        {{ $t('pageKey15', '新增') }}
                    </el-button>
                    <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="small"
                        @click="onDelete"
                        :disabled="!currentTableSelect.length"
                    >
                        {{ $t('pageKey17', '删除') }}
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
                :empty-text="$t('pageKey977', '暂无数据')"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="shopName"
                    :label="$t('pageKey142', '商户名称')"
                    min-width="100"
                    v-if="userData.appId === 1"
                />
                <el-table-column
                    prop="storeName"
                    :label="$t('pageKey698', '门店名称')"
                    min-width="100"
                />
                <el-table-column
                    prop="templateName"
                    :label="$t('pageKey168', '模板名称')"
                    min-width="100"
                />
                <el-table-column
                    prop="paymentTypeName"
                    :label="$t('pageKey171', '付款方式')"
                    min-width="200"
                />
                <el-table-column
                    :label="$t('pageKey12', '是否可用')"
                    width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.isAvailable ? $t('pageKey7', '是') : $t('pageKey8', '否') }}
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('pageKey170', '是否默认')"
                    width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.isDefault ? $t('pageKey7', '是') : $t('pageKey8', '否') }}
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('pageKey55', '创建时间')"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('pageKey57', '修改时间')"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.updateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    :label="$t('pageKey13', '操作')"
                    width="100"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/pfsp/spmg/shopPaymentConfig/' + scope.row.id)"
                        >
                            {{ $t('pageKey16', '编辑') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            {{ $t('pageKey17', '删除') }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Variable',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                shopId: '',
                storeName: '',
                templateName: '',
                isAvailable: '',
                isDefault: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Sp.ShopPaymentTemplate.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableDelete(ids) {
            this.$confirm(this.$t('pageKey41', '是否确定要删除!'), this.$t('pageKey42', '温馨提示'), {
                type: 'warning',
                confirmButtonText: this.$t('pageKey19', '确定'),
                cancelButtonText: this.$t('pageKey20', '取消'),
            }).then(() => {
                this.$api.Sp.ShopPaymentTemplate.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
    },
};
</script>

<style lang="scss">
</style>
