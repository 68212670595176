<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <el-row>
            <el-col :span="24">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-form-item
                        prop="shopId"
                        :label="$t('pageKey142', '商户')"
                        label-width="11em"
                        v-if="userData.appId === 1"
                    >
                        <RemoteSelect
                            filterable
                            remote="/sp/shop/select"
                            :placeholder="$t('pageKey238', '请输入')"
                            :props="{id:'id',name:'shopName'}"
                            v-model="formModel.shopId"
                            @change="changeShop"
                            style="width: 400px"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="templateName"
                        :label="$t('pageKey168', '模板名称')"
                        label-width="11em"
                    >
                        <el-input
                            v-model="formModel.templateName"
                            auto-complete="off"
                            style="width: 400px"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="storeIds"
                        :label="$t('pageKey620', '门店')"
                        label-width="11em"
                    >
                        <RemoteSelect
                            filterable
                            clearable
                            remote="/sp/shopStore/select"
                            :placeholder="$t('pageKey101', '请选择')"
                            :props="{id:'id',name:'storeName'}"
                            v-model="formModel.storeIds"
                            :remote-params="{shopId:this.formModel.shopId}"
                            disabled-cache
                            style="width: 400px"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="goodsType"
                        :label="$t('pageKey268', '商品类型')"
                        label-width="11em"
                    >
                        <checkbox-group-andOr
                            v-model="formModel.goodsType"
                            :data="goodsTypeList"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="goodsSendModes"
                        :label="$t('pageKey113', '商品发货方式')"
                        label-width="11em"
                    >
                        <el-checkbox-group v-model="formModel.goodsSendModes">
                            <el-checkbox
                                v-for="item in goodsSendModeList"
                                :key="item.id + ''"
                                :label="item.id + ''">
                                {{ item.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item
                        prop="orderTypes"
                        :label="$t('pageKey567', '订单类型')"
                        label-width="11em"
                    >
                        <el-checkbox-group v-model="formModel.orderTypes">
                            <el-checkbox
                                v-for="item in orderTypeList"
                                :key="item.id + ''"
                                :label="item.id + ''">
                                {{ item.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item
                        prop="shipMethod"
                        :label="$t('pageKey898', '订单送货方式')"
                        label-width="11em"
                    >
                        <checkbox-group-andOr
                            v-model="formModel.shipMethod"
                            :data="shipMethodList"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="paymentType"
                        :label="$t('pageKey171', '付款方式')"
                        label-width="11em"
                    >
                        <checkbox-group-andOr
                            v-model="formModel.paymentType"
                            :data="paymentTypeList"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isAvailable"
                        :label="$t('pageKey12', '是否可用')"
                        label-width="11em"
                    >
                        <el-switch
                            v-model="formModel.isAvailable"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isDefault"
                        :label="$t('pageKey170', '是否默认')"
                        label-width="11em"
                    >
                        <el-switch
                            v-model="formModel.isDefault"
                        />
                    </el-form-item>
                    <el-form-item
                        :label="$t('pageKey899', '付款配置')"
                        label-width="11em"
                    >
                        <el-tabs
                            tab-position="left"
                            v-model="tabName"
                        >
                            <el-tab-pane
                                v-for="item in shopPaymentConfig"
                                :key="item.paymentType"
                                :label="item.paymentTypeName"
                                :name="item.paymentTypeName"
                            >
                                <el-col
                                    :span="12"
                                    v-if="item.paymentType === 1"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="addTab(item)"
                                        class="ma-b"
                                        v-if="multipleConfig"
                                    >
                                        {{ $t('pageKey900', '添加配置') }}
                                    </el-button>
                                    <el-tabs
                                        v-model="item.editableTabsValue"
                                        type="card"
                                        :closable="multipleConfig"
                                        @tab-remove="($event)=>{removeTab($event,item)}"
                                    >
                                        <el-tab-pane
                                            :key="index"
                                            v-for="(configItem, index) in item.paymentConfig"
                                            :label="multipleConfig ? $t('pageKey901', '配置') + (index + 1) :$t('pageKey902', '支付配置')"
                                            :name="index"
                                        >
                                            <el-form-item
                                                prop="ratio"
                                                :label="$t('pageKey903', '权重')"
                                                label-width="11em"
                                                v-if="multipleConfig"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="configItem.ratio"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isAvailable"
                                                :label="$t('pageKey12', '是否启用')"
                                                label-width="11em"
                                                v-if="!!storeId"
                                            >
                                                <el-switch
                                                    v-model="configItem.isAvailable"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="bankAccountName"
                                                :label="$t('pageKey904', '银行开户名')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.bankAccountName"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="bankAccountNo"
                                                :label="$t('pageKey905', '银行账号')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.bankAccountNo"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="depositBank"
                                                :label="$t('pageKey906', '开户银行')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.depositBank"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="branchBankName"
                                                :label="$t('pageKey907', '支行名称')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.branchBankName"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                                <el-col
                                    :span="24"
                                    v-if="item.paymentType === 2"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="addTab(item)"
                                        class="ma-b"
                                        v-if="multipleConfig"
                                    >
                                        {{ $t('pageKey900', '添加配置') }}
                                    </el-button>
                                    <el-tabs
                                        v-model="item.editableTabsValue"
                                        type="card"
                                        :closable="multipleConfig"
                                        @tab-remove="($event)=>{removeTab($event,item)}"
                                    >
                                        <el-tab-pane
                                            :key="index"
                                            v-for="(configItem, index) in item.paymentConfig"
                                            :label="multipleConfig ? $t('pageKey901', '配置') + (index + 1) :$t('pageKey902', '支付配置')"
                                            :name="index"
                                        >
                                            <el-form-item
                                                prop="ratio"
                                                :label="$t('pageKey903', '权重')"
                                                label-width="11em"
                                                v-if="multipleConfig"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="configItem.ratio"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isAvailable"
                                                :label="$t('pageKey12', '是否启用')"
                                                label-width="11em"
                                                v-if="!!storeId"
                                            >
                                                <el-switch
                                                    v-model="configItem.isAvailable"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="wxaccountAppid"
                                                :label="$t('pageKey908', '公众号appid')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.wxaccountAppid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="wxaccountMchid"
                                                :label="$t('pageKey909', '支付商户ID')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.wxaccountMchid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="wxaccountMchkey"
                                                :label="$t('pageKey910', '支付商户key')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.wxaccountMchkey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="certSerialNo"
                                                :label="$t('pageKey911', '证书序列号')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.certSerialNo"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="subWxaccountAppid"
                                                :label="$t('pageKey912', '子公众号appid')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.subWxaccountAppid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="subWxaccountMchid"
                                                :label="$t('pageKey913', '子支付商户ID')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.subWxaccountMchid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="withdrawWxaccountMchid"
                                                :label="$t('pageKey914', '提现支付商户ID')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.withdrawWxaccountMchid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="withdrawWxaccountMchkey"
                                                :label="$t('pageKey915', '提现支付商户key')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.withdrawWxaccountMchkey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isMergePay"
                                                :label="$t('pageKey916', '是否合单支付')"
                                                label-width="11em"
                                            >
                                                <el-switch
                                                    v-model="configItem.isMergePay"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageRate"
                                                :label="$t('pageKey663', '手续费')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageCostRate"
                                                :label="$t('pageKey917', '手续费成本')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageCostRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="exchangeRate"
                                                :label="$t('pageKey918', '汇率')"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="6"
                                                    v-model.number="configItem.exchangeRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                :label="$t('pageKey919', '渠道商手续费')"
                                                label-width="11em"
                                            >
                                                <div class="ma-b">
                                                    <el-button @click="onAdd(configItem)">
                                                        {{ $t('pageKey15', '新增') }}
                                                    </el-button>
                                                </div>
                                                <el-table
                                                    stripe
                                                    border
                                                    size="small"
                                                    :data="configItem.externalPoundageAmountList"
                                                >
                                                    <el-table-column :label="$t('pageKey920', '付款渠道')">
                                                        <template slot-scope="scope">
                                                            <el-input
                                                                v-model="scope.row.paymentChannel"
                                                                :maxlength="20"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column :label="$t('pageKey921', '手续费比例')+'(%)'">
                                                        <template slot-scope="scope">
                                                            <el-input-number
                                                                controls-position="right"
                                                                :min="0"
                                                                :precision="2"
                                                                v-model.number="scope.row.poundageRate"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        :label="$t('pageKey13', '操作')"
                                                        width="60"
                                                    >
                                                        <template slot-scope="scope">
                                                            <el-button
                                                                size="small"
                                                                type="text"
                                                                @click="onDelete(scope.$index, configItem.externalPoundageAmountList)"
                                                            >
                                                                <span>{{ $t('pageKey17', '删除') }}</span>
                                                            </el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-form-item>
                                            <el-form-item
                                                prop="isCustomsClearance"
                                                :label="$t('pageKey922', '是否海关报送')"
                                                label-width="11em"
                                            >
                                                <el-switch
                                                    v-model="configItem.isCustomsClearance"
                                                />
                                            </el-form-item>
                                            <template
                                                v-if="configItem.isCustomsClearance">
                                                <el-form-item
                                                    prop="customsWxaccountAppid"
                                                    :label="$t('pageKey923', '报关公众号appid')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.customsWxaccountAppid"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="customsWxaccountMchid"
                                                    :label="$t('pageKey924', '报关商户ID')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.customsWxaccountMchid"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="customsWxaccountMchkey"
                                                    :label="$t('pageKey925', '报关商户key')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.customsWxaccountMchkey"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="customsPlace"
                                                    :label="$t('pageKey926', '海关编号')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.customsPlace"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="merchantCustomsName"
                                                    :label="$t('pageKey927', '商户海关备案名称')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.merchantCustomsName"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="merchantCustomsCode"
                                                    :label="$t('pageKey928', '商户海关备案编号')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.merchantCustomsCode"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                            </template>
                                            <el-form-item
                                                prop="isProfitSharing"
                                                :label="$t('pageKey929', '是否分账')"
                                                label-width="11em"
                                            >
                                                <el-switch
                                                    v-model="configItem.isProfitSharing"
                                                />
                                            </el-form-item>
                                            <template
                                                v-if="configItem.isProfitSharing">
                                                <el-form-item
                                                    :label="$t('pageKey930', '分账节点')"
                                                    label-width="11em"
                                                >
                                                    <el-select
                                                        v-model="configItem.profitSharingNodeType"
                                                        :placeholder="$t('pageKey101', '请选择')"
                                                        style="width: 100px"
                                                    >
                                                        <el-option
                                                            :label="$t('pageKey931', '订单下单')"
                                                            :value="1"
                                                        />
                                                        <el-option
                                                            :label="$t('pageKey932', '订单完成')"
                                                            :value="2"
                                                        />
                                                    </el-select>
                                                    <el-input-number
                                                        controls-position="right"
                                                        :min="0"
                                                        :precision="0"
                                                        v-model.number="configItem.profitSharingNodeDate"
                                                    />
                                                    {{ $t('pageKey933', '天后') }}
                                                </el-form-item>
                                                <el-form-item
                                                    :label="$t('pageKey934', '分账配置')"
                                                    label-width="11em"
                                                >
                                                    <div class="ma-b">
                                                        <el-button @click="onAdd1(configItem)">
                                                            {{ $t('pageKey15', '新增') }}
                                                        </el-button>
                                                    </div>
                                                    <el-table
                                                        stripe
                                                        border
                                                        size="small"
                                                        :data="configItem.profitSharingConfig"
                                                    >
                                                        <el-table-column
                                                            :label="$t('pageKey935', '是否主账号')"
                                                            width="90">
                                                            <template slot-scope="scope">
                                                                <el-switch
                                                                    v-model="scope.row.isMainAccount"
                                                                />
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column
                                                            :label="$t('pageKey936', '接收方类型')"
                                                            width="140">
                                                            <template slot-scope="scope">
                                                                <el-select
                                                                    v-model="scope.row.type"
                                                                    :placeholder="$t('pageKey101', '请选择')"
                                                                    style="width: 120px"
                                                                >
                                                                    <el-option
                                                                        :label="$t('pageKey937', '商户号')"
                                                                        value="MERCHANT_ID"
                                                                    />
                                                                    <el-option
                                                                        :label="$t('pageKey938', '个人')"
                                                                        value="PERSONAL_OPENID"
                                                                    />
                                                                </el-select>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column :label="$t('pageKey939', '接收方信息')">
                                                            <template slot-scope="scope">
                                                                <div>
                                                                    <span v-if="scope.row.type == 'MERCHANT_ID'">{{ $t('pageKey937', '商户号') }}：</span>
                                                                    <span v-else>{{ $t('pageKey938', '个人') }}openid：</span>
                                                                    <el-input
                                                                        v-model="scope.row.account"
                                                                        :maxlength="64"
                                                                        style="width: 300px"
                                                                    />
                                                                </div>
                                                                <div
                                                                    class="ma-t">
                                                                    <span v-if="scope.row.type == 'MERCHANT_ID'">{{ $t('pageKey940', '商户全称') }}：</span>
                                                                    <span v-else>{{ $t('pageKey941', '个人姓名') }}：</span>
                                                                    <el-input
                                                                        v-model="scope.row.name"
                                                                        :maxlength="64"
                                                                        style="width: 300px"
                                                                    />
                                                                </div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column
                                                            :label="$t('pageKey942', '分账比例')+'(%)'"
                                                            width="150">
                                                            <template slot-scope="scope">
                                                                <el-input-number
                                                                    controls-position="right"
                                                                    :min="0"
                                                                    :max="100"
                                                                    :precision="2"
                                                                    v-model.number="scope.row.rate"
                                                                />
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column
                                                            :label="$t('pageKey13', '操作')"
                                                            width="60"
                                                        >
                                                            <template slot-scope="scope">
                                                                <el-button
                                                                    size="small"
                                                                    type="text"
                                                                    @click="onDelete1(scope.$index, configItem.profitSharingConfig)"
                                                                >
                                                                    <span>{{ $t('pageKey17', '删除') }}</span>
                                                                </el-button>
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                </el-form-item>
                                            </template>
                                            <el-form-item
                                                prop="isFundExit"
                                                :label="$t('pageKey943', '是否资金出境')"
                                                label-width="11em"
                                            >
                                                <el-switch
                                                    v-model="configItem.isFundExit"
                                                />
                                            </el-form-item>
                                            <template
                                                v-if="configItem.isFundExit">
                                                <el-form-item
                                                    prop="fundExitRate"
                                                    :label="$t('pageKey944', '出境比例')+'（%）'"
                                                    label-width="11em"
                                                >
                                                    <el-input-number
                                                        controls-position="right"
                                                        :min="0"
                                                        :precision="2"
                                                        v-model.number="configItem.fundExitConfig.fundExitRate"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="foreignCurrency"
                                                    :label="$t('pageKey945', '境外收款币种')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.fundExitConfig.foreignCurrency"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="overseaBusinessName"
                                                    :label="$t('pageKey946', '境外卖家经营主体名称')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.fundExitConfig.overseaBusinessName"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="overseaShopName"
                                                    :label="$t('pageKey947', '境外卖家店铺名称')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.fundExitConfig.overseaShopName"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="sellerId"
                                                    :label="$t('pageKey948', '卖家ID')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.fundExitConfig.sellerId"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="payeeId"
                                                    :label="$t('pageKey949', '收款人识别号')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.fundExitConfig.payeeId"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                            </template>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                                <el-col
                                    :span="12"
                                    v-if="item.paymentType === 4"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="addTab(item)"
                                        class="ma-b"
                                        v-if="multipleConfig"
                                    >
                                        {{ $t('pageKey900', '添加配置') }}
                                    </el-button>
                                    <el-tabs
                                        v-model="item.editableTabsValue"
                                        type="card"
                                        :closable="multipleConfig"
                                        @tab-remove="($event)=>{removeTab($event,item)}"
                                    >
                                        <el-tab-pane
                                            :key="index"
                                            v-for="(configItem, index) in item.paymentConfig"
                                            :label="multipleConfig ? $t('pageKey901', '配置') + (index + 1) :$t('pageKey902', '支付配置')"
                                            :name="index"
                                        >
                                            <el-form-item
                                                prop="ratio"
                                                :label="$t('pageKey903', '权重')"
                                                label-width="11em"
                                                v-if="multipleConfig"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="configItem.ratio"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isAvailable"
                                                :label="$t('pageKey12', '是否启用')"
                                                label-width="11em"
                                                v-if="!!storeId"
                                            >
                                                <el-switch
                                                    v-model="configItem.isAvailable"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="unionpayMid"
                                                :label="$t('pageKey950', '银联支付商户号')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.unionpayMid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="unionpayTid"
                                                :label="$t('pageKey951', '银联支付终端号')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.unionpayTid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="unionpayInstmid"
                                                :label="$t('pageKey952', '银联支付机构商户号')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.unionpayInstmid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="unionpayMsgsrc"
                                                :label="$t('pageKey953', '银联支付消息来源')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.unionpayMsgsrc"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="unionpayMsgsrcid"
                                                :label="$t('pageKey954', '银联支付来源编号')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.unionpayMsgsrcid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="unionpayKey"
                                                :label="$t('pageKey955', '银联支付密钥')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.unionpayKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageRate"
                                                :label="$t('pageKey663', '手续费')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageCostRate"
                                                :label="$t('pageKey917', '手续费成本')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageCostRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="exchangeRate"
                                                :label="$t('pageKey918', '汇率')"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="6"
                                                    v-model.number="configItem.exchangeRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                :label="$t('pageKey919', '渠道商手续费')"
                                                label-width="11em"
                                            >
                                                <div class="ma-b">
                                                    <el-button @click="onAdd(configItem)">
                                                        {{ $t('pageKey15', '新增') }}
                                                    </el-button>
                                                </div>
                                                <el-table
                                                    stripe
                                                    border
                                                    size="small"
                                                    :data="configItem.externalPoundageAmountList"
                                                >
                                                    <el-table-column :label="$t('pageKey920', '付款渠道')">
                                                        <template slot-scope="scope">
                                                            <el-input
                                                                v-model="scope.row.paymentChannel"
                                                                :maxlength="20"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column :label="$t('pageKey921', '手续费比例')+'(%)'">
                                                        <template slot-scope="scope">
                                                            <el-input-number
                                                                controls-position="right"
                                                                :min="0"
                                                                :precision="2"
                                                                v-model.number="scope.row.poundageRate"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        :label="$t('pageKey13', '操作')"
                                                        width="60"
                                                    >
                                                        <template slot-scope="scope">
                                                            <el-button
                                                                size="small"
                                                                type="text"
                                                                @click="onDelete(scope.$index, configItem.externalPoundageAmountList)"
                                                            >
                                                                <span>{{ $t('pageKey17', '删除') }}</span>
                                                            </el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-form-item>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                                <el-col
                                    :span="12"
                                    v-if="item.paymentType === 8"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="addTab(item)"
                                        class="ma-b"
                                        v-if="multipleConfig"
                                    >
                                        {{ $t('pageKey900', '添加配置') }}
                                    </el-button>
                                    <el-tabs
                                        v-model="item.editableTabsValue"
                                        type="card"
                                        :closable="multipleConfig"
                                        @tab-remove="($event)=>{removeTab($event,item)}"
                                    >
                                        <el-tab-pane
                                            :key="index"
                                            v-for="(configItem, index) in item.paymentConfig"
                                            :label="multipleConfig ? $t('pageKey901', '配置') + (index + 1) :$t('pageKey902', '支付配置')"
                                            :name="index"
                                        >
                                            <el-form-item
                                                prop="ratio"
                                                :label="$t('pageKey903', '权重')"
                                                label-width="11em"
                                                v-if="multipleConfig"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="configItem.ratio"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isAvailable"
                                                :label="$t('pageKey12', '是否启用')"
                                                label-width="11em"
                                                v-if="!!storeId"
                                            >
                                                <el-switch
                                                    v-model="configItem.isAvailable"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="allinpayCusid"
                                                :label="$t('pageKey956', '通联支付商户号')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.allinpayCusid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="allinpayAppid"
                                                :label="$t('pageKey957', '通联支付APPID')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.allinpayAppid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="allinpayKey"
                                                :label="$t('pageKey958', '通联支付密钥')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.allinpayKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageRate"
                                                :label="$t('pageKey663', '手续费')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageCostRate"
                                                :label="$t('pageKey917', '手续费成本')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageCostRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="exchangeRate"
                                                :label="$t('pageKey918', '汇率')"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="6"
                                                    v-model.number="configItem.exchangeRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                :label="$t('pageKey919', '渠道商手续费')"
                                                label-width="11em"
                                            >
                                                <div class="ma-b">
                                                    <el-button @click="onAdd(configItem)">
                                                        {{ $t('pageKey15', '新增') }}
                                                    </el-button>
                                                </div>
                                                <el-table
                                                    stripe
                                                    border
                                                    size="small"
                                                    :data="configItem.externalPoundageAmountList"
                                                >
                                                    <el-table-column :label="$t('pageKey920', '付款渠道')">
                                                        <template slot-scope="scope">
                                                            <el-input
                                                                v-model="scope.row.paymentChannel"
                                                                :maxlength="20"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column :label="$t('pageKey921', '手续费比例')+'(%)'">
                                                        <template slot-scope="scope">
                                                            <el-input-number
                                                                controls-position="right"
                                                                :min="0"
                                                                :precision="2"
                                                                v-model.number="scope.row.poundageRate"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        :label="$t('pageKey13', '操作')"
                                                        width="60"
                                                    >
                                                        <template slot-scope="scope">
                                                            <el-button
                                                                size="small"
                                                                type="text"
                                                                @click="onDelete(scope.$index, configItem.externalPoundageAmountList)"
                                                            >
                                                                <span>{{ $t('pageKey17', '删除') }}</span>
                                                            </el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-form-item>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                                <el-col
                                    :span="12"
                                    v-if="item.paymentType === 16"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="addTab(item)"
                                        class="ma-b"
                                        v-if="multipleConfig"
                                    >
                                        {{ $t('pageKey900', '添加配置') }}
                                    </el-button>
                                    <el-tabs
                                        v-model="item.editableTabsValue"
                                        type="card"
                                        :closable="multipleConfig"
                                        @tab-remove="($event)=>{removeTab($event,item)}"
                                    >
                                        <el-tab-pane
                                            :key="index"
                                            v-for="(configItem, index) in item.paymentConfig"
                                            :label="multipleConfig ? $t('pageKey901', '配置') + (index + 1) :$t('pageKey902', '支付配置')"
                                            :name="index"
                                        >
                                            <el-form-item
                                                prop="ratio"
                                                :label="$t('pageKey903', '权重')"
                                                label-width="11em"
                                                v-if="multipleConfig"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="configItem.ratio"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isAvailable"
                                                :label="$t('pageKey12', '是否启用')"
                                                label-width="11em"
                                                v-if="!!storeId"
                                            >
                                                <el-switch
                                                    v-model="configItem.isAvailable"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="alphapayPartnercode"
                                                label="alphapay Partnercode"
                                                label-width="12em"
                                            >
                                                <el-input
                                                    v-model="configItem.alphapayPartnercode"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="alphapayCredentialcode"
                                                label="alphapay Credentialcode"
                                                label-width="12em"
                                            >
                                                <el-input
                                                    v-model="configItem.alphapayCredentialcode"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="currency"
                                                :label="$t('pageKey959', '币种')"
                                                label-width="12em"
                                            >
                                                <el-input
                                                    v-model="configItem.currency"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="apiUrl"
                                                :label="$t('pageKey960', '请求地址')"
                                                label-width="12em"
                                            >
                                                <el-input
                                                    v-model="configItem.apiUrl"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageRate"
                                                :label="$t('pageKey663', '手续费')+'(%)'"
                                                label-width="12em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageCostRate"
                                                :label="$t('pageKey917', '手续费成本')+'(%)'"
                                                label-width="12em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageCostRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="exchangeRate"
                                                :label="$t('pageKey918', '汇率')"
                                                label-width="12em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="6"
                                                    v-model.number="configItem.exchangeRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                :label="$t('pageKey919', '渠道商手续费')"
                                                label-width="12em"
                                            >
                                                <div class="ma-b">
                                                    <el-button @click="onAdd(configItem)">
                                                        {{ $t('pageKey15', '新增') }}
                                                    </el-button>
                                                </div>
                                                <el-table
                                                    stripe
                                                    border
                                                    size="small"
                                                    :data="configItem.externalPoundageAmountList"
                                                >
                                                    <el-table-column :label="$t('pageKey920', '付款渠道')">
                                                        <template slot-scope="scope">
                                                            <el-input
                                                                v-model="scope.row.paymentChannel"
                                                                :maxlength="20"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column :label="$t('pageKey921', '手续费比例')+'(%)'">
                                                        <template slot-scope="scope">
                                                            <el-input-number
                                                                controls-position="right"
                                                                :min="0"
                                                                :precision="2"
                                                                v-model.number="scope.row.poundageRate"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        :label="$t('pageKey13', '操作')"
                                                        width="60"
                                                    >
                                                        <template slot-scope="scope">
                                                            <el-button
                                                                size="small"
                                                                type="text"
                                                                @click="onDelete(scope.$index, configItem.externalPoundageAmountList)"
                                                            >
                                                                <span>{{ $t('pageKey17', '删除') }}</span>
                                                            </el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-form-item>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                                <el-col
                                    :span="12"
                                    v-if="item.paymentType === 32"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="addTab(item)"
                                        class="ma-b"
                                        v-if="multipleConfig"
                                    >
                                        {{ $t('pageKey900', '添加配置') }}
                                    </el-button>
                                    <el-tabs
                                        v-model="item.editableTabsValue"
                                        type="card"
                                        :closable="multipleConfig"
                                        @tab-remove="($event)=>{removeTab($event,item)}"
                                    >
                                        <el-tab-pane
                                            :key="index"
                                            v-for="(configItem, index) in item.paymentConfig"
                                            :label="multipleConfig ? $t('pageKey901', '配置') + (index + 1) :$t('pageKey902', '支付配置')"
                                            :name="index"
                                        >
                                            <el-form-item
                                                prop="ratio"
                                                :label="$t('pageKey903', '权重')"
                                                label-width="11em"
                                                v-if="multipleConfig"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="configItem.ratio"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isAvailable"
                                                :label="$t('pageKey12', '是否启用')"
                                                label-width="11em"
                                                v-if="!!storeId"
                                            >
                                                <el-switch
                                                    v-model="configItem.isAvailable"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="alipayAppid"
                                                :label="$t('pageKey961', '支付宝商户appid')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.alipayAppid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="alipayPublickey"
                                                :label="$t('pageKey962', '支付宝公钥')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.alipayPublickey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="alipayPrivatekey"
                                                :label="$t('pageKey963', '支付宝商户私钥')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.alipayPrivatekey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="appAuthToken"
                                                :label="$t('pageKey964', '子商户授权token')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.appAuthToken"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="sysServiceProviderId"
                                                :label="$t('pageKey965', '活动PID')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.sysServiceProviderId"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isMergePay"
                                                :label="$t('pageKey916', '是否合单支付')"
                                                label-width="11em"
                                            >
                                                <el-switch
                                                    v-model="configItem.isMergePay"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="merchantId"
                                                :label="$t('pageKey966', '子商户ID')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.merchantId"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageRate"
                                                :label="$t('pageKey663', '手续费')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageCostRate"
                                                :label="$t('pageKey917', '手续费成本')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageCostRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="exchangeRate"
                                                :label="$t('pageKey918', '汇率')"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="6"
                                                    v-model.number="configItem.exchangeRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                :label="$t('pageKey919', '渠道商手续费')"
                                                label-width="11em"
                                            >
                                                <div class="ma-b">
                                                    <el-button @click="onAdd(configItem)">
                                                        {{ $t('pageKey15', '新增') }}
                                                    </el-button>
                                                </div>
                                                <el-table
                                                    stripe
                                                    border
                                                    size="small"
                                                    :data="configItem.externalPoundageAmountList"
                                                >
                                                    <el-table-column :label="$t('pageKey920', '付款渠道')">
                                                        <template slot-scope="scope">
                                                            <el-input
                                                                v-model="scope.row.paymentChannel"
                                                                :maxlength="20"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column :label="$t('pageKey921', '手续费比例')+'(%)'">
                                                        <template slot-scope="scope">
                                                            <el-input-number
                                                                controls-position="right"
                                                                :min="0"
                                                                :precision="2"
                                                                v-model.number="scope.row.poundageRate"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        :label="$t('pageKey13', '操作')"
                                                        width="60"
                                                    >
                                                        <template slot-scope="scope">
                                                            <el-button
                                                                size="small"
                                                                type="text"
                                                                @click="onDelete(scope.$index, configItem.externalPoundageAmountList)"
                                                            >
                                                                <span>{{ $t('pageKey17', '删除') }}</span>
                                                            </el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-form-item>
                                            <el-form-item
                                                prop="isCustomsClearance"
                                                :label="$t('pageKey922', '是否海关报送')"
                                                label-width="11em"
                                            >
                                                <el-switch
                                                    v-model="configItem.isCustomsClearance"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="customsPlace"
                                                :label="$t('pageKey926', '海关编号')"
                                                label-width="11em"
                                                v-if="configItem.isCustomsClearance"
                                            >
                                                <el-input
                                                    v-model="configItem.customsPlace"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="merchantCustomsName"
                                                :label="$t('pageKey927', '商户海关备案名称')"
                                                label-width="11em"
                                                v-if="configItem.isCustomsClearance"
                                            >
                                                <el-input
                                                    v-model="configItem.merchantCustomsName"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="merchantCustomsCode"
                                                :label="$t('pageKey928', '商户海关备案编号')"
                                                label-width="11em"
                                                v-if="configItem.isCustomsClearance"
                                            >
                                                <el-input
                                                    v-model="configItem.merchantCustomsCode"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="partner"
                                                :label="$t('pageKey967', '合作者身份ID')"
                                                label-width="11em"
                                                v-if="configItem.isCustomsClearance"
                                            >
                                                <el-input
                                                    v-model="configItem.partner"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="partnerKey"
                                                :label="$t('pageKey968', '合作者密钥')"
                                                label-width="11em"
                                                v-if="configItem.isCustomsClearance"
                                            >
                                                <el-input
                                                    v-model="configItem.partnerKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                                <el-col
                                    :span="12"
                                    v-if="item.paymentType === 64"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="addTab(item)"
                                        class="ma-b"
                                        v-if="multipleConfig"
                                    >
                                        {{ $t('pageKey900', '添加配置') }}
                                    </el-button>
                                    <el-tabs
                                        v-model="item.editableTabsValue"
                                        type="card"
                                        :closable="multipleConfig"
                                        @tab-remove="($event)=>{removeTab($event,item)}"
                                    >
                                        <el-tab-pane
                                            :key="index"
                                            v-for="(configItem, index) in item.paymentConfig"
                                            :label="multipleConfig ? $t('pageKey901', '配置') + (index + 1) :$t('pageKey902', '支付配置')"
                                            :name="index"
                                        >
                                            <el-form-item
                                                prop="ratio"
                                                :label="$t('pageKey903', '权重')"
                                                label-width="11em"
                                                v-if="multipleConfig"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="configItem.ratio"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isAvailable"
                                                :label="$t('pageKey12', '是否启用')"
                                                label-width="11em"
                                                v-if="!!storeId"
                                            >
                                                <el-switch
                                                    v-model="configItem.isAvailable"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="secretKey"
                                                label="secretKey"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.secretKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="publishableKey"
                                                label="publishableKey"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.publishableKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="currency"
                                                :label="$t('pageKey959', '币种')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.currency"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="domainName"
                                                :label="$t('pageKey969', '域名')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.domainName"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageRate"
                                                :label="$t('pageKey663', '手续费')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageCostRate"
                                                :label="$t('pageKey917', '手续费成本')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageCostRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="exchangeRate"
                                                :label="$t('pageKey918', '汇率')"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="6"
                                                    v-model.number="configItem.exchangeRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                :label="$t('pageKey919', '渠道商手续费')"
                                                label-width="11em"
                                            >
                                                <div class="ma-b">
                                                    <el-button @click="onAdd(configItem)">
                                                        {{ $t('pageKey15', '新增') }}
                                                    </el-button>
                                                </div>
                                                <el-table
                                                    stripe
                                                    border
                                                    size="small"
                                                    :data="configItem.externalPoundageAmountList"
                                                >
                                                    <el-table-column :label="$t('pageKey920', '付款渠道')">
                                                        <template slot-scope="scope">
                                                            <el-input
                                                                v-model="scope.row.paymentChannel"
                                                                :maxlength="20"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column :label="$t('pageKey921', '手续费比例')+'(%)'">
                                                        <template slot-scope="scope">
                                                            <el-input-number
                                                                controls-position="right"
                                                                :min="0"
                                                                :precision="2"
                                                                v-model.number="scope.row.poundageRate"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        :label="$t('pageKey13', '操作')"
                                                        width="60"
                                                    >
                                                        <template slot-scope="scope">
                                                            <el-button
                                                                size="small"
                                                                type="text"
                                                                @click="onDelete(scope.$index, configItem.externalPoundageAmountList)"
                                                            >
                                                                <span>{{ $t('pageKey17', '删除') }}</span>
                                                            </el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-form-item>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                                <el-col
                                    :span="12"
                                    v-if="item.paymentType === 128"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="addTab(item)"
                                        class="ma-b"
                                        v-if="multipleConfig"
                                    >
                                        {{ $t('pageKey900', '添加配置') }}
                                    </el-button>
                                    <el-tabs
                                        v-model="item.editableTabsValue"
                                        type="card"
                                        :closable="multipleConfig"
                                        @tab-remove="($event)=>{removeTab($event,item)}"
                                    >
                                        <el-tab-pane
                                            :key="index"
                                            v-for="(configItem, index) in item.paymentConfig"
                                            :label="multipleConfig ? $t('pageKey901', '配置') + (index + 1) :$t('pageKey902', '支付配置')"
                                            :name="index"
                                        >
                                            <el-form-item
                                                prop="ratio"
                                                :label="$t('pageKey903', '权重')"
                                                label-width="11em"
                                                v-if="multipleConfig"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="configItem.ratio"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isAvailable"
                                                :label="$t('pageKey12', '是否启用')"
                                                label-width="11em"
                                                v-if="!!storeId"
                                            >
                                                <el-switch
                                                    v-model="configItem.isAvailable"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentSnPrefix"
                                                :label="$t('pageKey970', '付款单号前缀')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.paymentSnPrefix"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="appId"
                                                label="appId"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.appId"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="mchntCd"
                                                :label="$t('pageKey937', '商户号')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.mchntCd"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="mchntKey"
                                                :label="$t('pageKey142', '商户')+'key'"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.mchntKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageRate"
                                                :label="$t('pageKey663', '手续费')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageCostRate"
                                                :label="$t('pageKey917', '手续费成本')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageCostRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="exchangeRate"
                                                :label="$t('pageKey918', '汇率')"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="6"
                                                    v-model.number="configItem.exchangeRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                :label="$t('pageKey919', '渠道商手续费')"
                                                label-width="11em"
                                            >
                                                <div class="ma-b">
                                                    <el-button @click="onAdd(configItem)">
                                                        {{ $t('pageKey15', '新增') }}
                                                    </el-button>
                                                </div>
                                                <el-table
                                                    stripe
                                                    border
                                                    size="small"
                                                    :data="configItem.externalPoundageAmountList"
                                                >
                                                    <el-table-column :label="$t('pageKey920', '付款渠道')">
                                                        <template slot-scope="scope">
                                                            <el-input
                                                                v-model="scope.row.paymentChannel"
                                                                :maxlength="20"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column :label="$t('pageKey921', '手续费比例')+'(%)'">
                                                        <template slot-scope="scope">
                                                            <el-input-number
                                                                controls-position="right"
                                                                :min="0"
                                                                :precision="2"
                                                                v-model.number="scope.row.poundageRate"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        :label="$t('pageKey13', '操作')"
                                                        width="60"
                                                    >
                                                        <template slot-scope="scope">
                                                            <el-button
                                                                size="small"
                                                                type="text"
                                                                @click="onDelete(scope.$index, configItem.externalPoundageAmountList)"
                                                            >
                                                                <span>{{ $t('pageKey17', '删除') }}</span>
                                                            </el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-form-item>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                                <el-col
                                    :span="24"
                                    v-if="item.paymentType === 256"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="addTab(item)"
                                        class="ma-b"
                                        v-if="multipleConfig"
                                    >
                                        {{ $t('pageKey900', '添加配置') }}
                                    </el-button>
                                    <el-tabs
                                        v-model="item.editableTabsValue"
                                        type="card"
                                        :closable="multipleConfig"
                                        @tab-remove="($event)=>{removeTab($event,item)}"
                                    >
                                        <el-tab-pane
                                            :key="index"
                                            v-for="(configItem, index) in item.paymentConfig"
                                            :label="multipleConfig ? $t('pageKey901', '配置') + (index + 1) :$t('pageKey902', '支付配置')"
                                            :name="index"
                                        >
                                            <el-form-item
                                                prop="ratio"
                                                :label="$t('pageKey903', '权重')"
                                                label-width="11em"
                                                v-if="multipleConfig"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="configItem.ratio"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isAvailable"
                                                :label="$t('pageKey12', '是否启用')"
                                                label-width="11em"
                                                v-if="!!storeId"
                                            >
                                                <el-switch
                                                    v-model="configItem.isAvailable"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentSnPrefix"
                                                :label="$t('pageKey970', '付款单号前缀')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.paymentSnPrefix"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="appId"
                                                label="appId"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.appId"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="appKey"
                                                label="appKey"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.appKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="mid"
                                                :label="$t('pageKey937', '商户号')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.mid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="subAppId"
                                                :label="$t('pageKey971', '子商户appId')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.subAppId"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="terminalSn"
                                                :label="$t('pageKey972', '终端号')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.terminalSn"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="secretKey"
                                                :label="$t('pageKey973', '通讯密钥')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.secretKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageRate"
                                                :label="$t('pageKey663', '手续费')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageCostRate"
                                                :label="$t('pageKey917', '手续费成本')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageCostRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="exchangeRate"
                                                :label="$t('pageKey918', '汇率')"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="6"
                                                    v-model.number="configItem.exchangeRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                :label="$t('pageKey919', '渠道商手续费')"
                                                label-width="11em"
                                            >
                                                <div class="ma-b">
                                                    <el-button @click="onAdd(configItem)">
                                                        {{ $t('pageKey15', '新增') }}
                                                    </el-button>
                                                </div>
                                                <el-table
                                                    stripe
                                                    border
                                                    size="small"
                                                    :data="configItem.externalPoundageAmountList"
                                                >
                                                    <el-table-column :label="$t('pageKey920', '付款渠道')">
                                                        <template slot-scope="scope">
                                                            <el-input
                                                                v-model="scope.row.paymentChannel"
                                                                :maxlength="20"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column :label="$t('pageKey921', '手续费比例')+'(%)'">
                                                        <template slot-scope="scope">
                                                            <el-input-number
                                                                controls-position="right"
                                                                :min="0"
                                                                :precision="2"
                                                                v-model.number="scope.row.poundageRate"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        :label="$t('pageKey13', '操作')"
                                                        width="60"
                                                    >
                                                        <template slot-scope="scope">
                                                            <el-button
                                                                size="small"
                                                                type="text"
                                                                @click="onDelete(scope.$index, configItem.externalPoundageAmountList)"
                                                            >
                                                                <span>{{ $t('pageKey17', '删除') }}</span>
                                                            </el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-form-item>
                                            <el-form-item
                                                prop="isProfitSharing"
                                                :label="$t('pageKey929', '是否分账')"
                                                label-width="11em"
                                            >
                                                <el-switch
                                                    v-model="configItem.isProfitSharing"
                                                />
                                            </el-form-item>
                                            <template
                                                v-if="configItem.isProfitSharing">
                                                <!--                                            <el-form-item
                                                                                                label="分账节点"
                                                                                                label-width="11em"
                                                                                            >
                                                                                                <el-select
                                                                                                    v-model="configItem.profitSharingNodeType"
                                                                                                     :placeholder="$t('pageKey101', '请选择')"
                                                                                                    style="width: 100px"
                                                                                                >
                                                                                                    <el-option
                                                                                                        label="订单下单"
                                                                                                        :value="1"
                                                                                                    />
                                                                                                    <el-option
                                                                                                        label="订单完成"
                                                                                                        :value="2"
                                                                                                    />
                                                                                                </el-select>
                                                                                                <el-input-number
                                                                                                    controls-position="right"
                                                                                                    :min="0"
                                                                                                    :precision="0"
                                                                                                    v-model.number="configItem.profitSharingNodeDate"
                                                                                                />
                                                                                                天后
                                                                                            </el-form-item>-->
                                                <el-form-item
                                                    :label="$t('pageKey934', '分账配置')"
                                                    label-width="11em"
                                                >
                                                    <div class="ma-b">
                                                        <el-button @click="onAdd1(configItem)">
                                                            {{ $t('pageKey15', '新增') }}
                                                        </el-button>
                                                    </div>
                                                    <el-table
                                                        stripe
                                                        border
                                                        size="small"
                                                        :data="configItem.profitSharingConfig"
                                                    >
                                                        <el-table-column
                                                            :label="$t('pageKey935', '是否主账号')"
                                                            width="90">
                                                            <template slot-scope="scope">
                                                                <el-switch
                                                                    v-model="scope.row.isMainAccount"
                                                                />
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column
                                                            :label="$t('pageKey936', '接收方类型')"
                                                            width="140">
                                                            <template slot-scope="scope">
                                                                <el-select
                                                                    v-model="scope.row.type"
                                                                    :placeholder="$t('pageKey101', '请选择')"
                                                                    style="width: 120px"
                                                                >
                                                                    <el-option
                                                                        :label="$t('pageKey937', '商户号')"
                                                                        value="MERCHANT_ID"
                                                                    />
                                                                    <!--                                                                                                                                    <el-option
                                                                                                                                        :label="$t('pageKey938', '个人')"
                                                                                                                                        value="PERSONAL_OPENID"
                                                                                                                                    />-->
                                                                </el-select>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column :label="$t('pageKey939', '接收方信息')">
                                                            <template slot-scope="scope">
                                                                <div>
                                                                    <span v-if="scope.row.type == 'MERCHANT_ID'">{{ $t('pageKey937', '商户号') }}：</span>
                                                                    <span v-else>{{ $t('pageKey938', '个人') }}openid：</span>
                                                                    <el-input
                                                                        v-model="scope.row.account"
                                                                        :maxlength="64"
                                                                        style="width: 300px"
                                                                    />
                                                                </div>
                                                                <div
                                                                    class="ma-t">
                                                                    <span v-if="scope.row.type == 'MERCHANT_ID'">{{ $t('pageKey940', '商户全称') }}：</span>
                                                                    <span v-else>{{ $t('pageKey941', '个人姓名') }}：</span>
                                                                    <el-input
                                                                        v-model="scope.row.name"
                                                                        :maxlength="64"
                                                                        style="width: 300px"
                                                                    />
                                                                </div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column
                                                            :label="$t('pageKey942', '分账比例')+'(%)'"
                                                            width="150">
                                                            <template slot-scope="scope">
                                                                <el-input-number
                                                                    controls-position="right"
                                                                    :min="0"
                                                                    :max="100"
                                                                    :precision="2"
                                                                    v-model.number="scope.row.rate"
                                                                />
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column
                                                            :label="$t('pageKey13', '操作')"
                                                            width="60"
                                                        >
                                                            <template slot-scope="scope">
                                                                <el-button
                                                                    size="small"
                                                                    type="text"
                                                                    @click="onDelete1(scope.$index, configItem.profitSharingConfig)"
                                                                >
                                                                    <span>{{ $t('pageKey17', '删除') }}</span>
                                                                </el-button>
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                </el-form-item>
                                            </template>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                                <el-col
                                    :span="12"
                                    v-if="item.paymentType === 512"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="addTab(item)"
                                        class="ma-b"
                                        v-if="multipleConfig"
                                    >
                                        {{ $t('pageKey900', '添加配置') }}
                                    </el-button>
                                    <el-tabs
                                        v-model="item.editableTabsValue"
                                        type="card"
                                        :closable="multipleConfig"
                                        @tab-remove="($event)=>{removeTab($event,item)}"
                                    >
                                        <el-tab-pane
                                            :key="index"
                                            v-for="(configItem, index) in item.paymentConfig"
                                            :label="multipleConfig ? $t('pageKey901', '配置') + (index + 1) :$t('pageKey902', '支付配置')"
                                            :name="index"
                                        >
                                            <el-form-item
                                                prop="ratio"
                                                :label="$t('pageKey903', '权重')"
                                                label-width="11em"
                                                v-if="multipleConfig"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="configItem.ratio"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isAvailable"
                                                :label="$t('pageKey12', '是否启用')"
                                                label-width="11em"
                                                v-if="!!storeId"
                                            >
                                                <el-switch
                                                    v-model="configItem.isAvailable"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="storeId"
                                                label="门店ID"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.storeId"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="mchId"
                                                label="微信商户号"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.mchId"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="appId"
                                                label="微信appId"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.appId"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="partner"
                                                label="支付宝商户号"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.partner"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="key"
                                                label="key"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.key"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageRate"
                                                :label="$t('pageKey663', '手续费')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageCostRate"
                                                :label="$t('pageKey917', '手续费成本')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageCostRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="exchangeRate"
                                                :label="$t('pageKey918', '汇率')"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="6"
                                                    v-model.number="configItem.exchangeRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                :label="$t('pageKey919', '渠道商手续费')"
                                                label-width="11em"
                                            >
                                                <div class="ma-b">
                                                    <el-button @click="onAdd(configItem)">
                                                        {{ $t('pageKey15', '新增') }}
                                                    </el-button>
                                                </div>
                                                <el-table
                                                    stripe
                                                    border
                                                    size="small"
                                                    :data="configItem.externalPoundageAmountList"
                                                >
                                                    <el-table-column :label="$t('pageKey920', '付款渠道')">
                                                        <template slot-scope="scope">
                                                            <el-input
                                                                v-model="scope.row.paymentChannel"
                                                                :maxlength="20"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column :label="$t('pageKey921', '手续费比例')+'(%)'">
                                                        <template slot-scope="scope">
                                                            <el-input-number
                                                                controls-position="right"
                                                                :min="0"
                                                                :precision="2"
                                                                v-model.number="scope.row.poundageRate"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        :label="$t('pageKey13', '操作')"
                                                        width="60"
                                                    >
                                                        <template slot-scope="scope">
                                                            <el-button
                                                                size="small"
                                                                type="text"
                                                                @click="onDelete(scope.$index, configItem.externalPoundageAmountList)"
                                                            >
                                                                <span>{{ $t('pageKey17', '删除') }}</span>
                                                            </el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-form-item>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                                <el-col
                                    :span="24"
                                    v-if="item.paymentType === 4096"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="addTab(item)"
                                        class="ma-b"
                                        v-if="multipleConfig"
                                    >
                                        {{ $t('pageKey900', '添加配置') }}
                                    </el-button>
                                    <el-tabs
                                        v-model="item.editableTabsValue"
                                        type="card"
                                        :closable="multipleConfig"
                                        @tab-remove="($event)=>{removeTab($event,item)}"
                                    >
                                        <el-tab-pane
                                            :key="index"
                                            v-for="(configItem, index) in item.paymentConfig"
                                            :label="multipleConfig ?  $t('pageKey901', '配置') + (index + 1) :$t('pageKey902', '支付配置')"
                                            :name="index"
                                        >
                                            <el-form-item
                                                prop="ratio"
                                                :label="$t('pageKey903', '权重')"
                                                label-width="11em"
                                                v-if="multipleConfig"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="configItem.ratio"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isAvailable"
                                                :label="$t('pageKey12', '是否启用')"
                                                label-width="11em"
                                                v-if="!!storeId"
                                            >
                                                <el-switch
                                                    v-model="configItem.isAvailable"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="mpaccountAppid"
                                                :label="$t('pageKey32', '小程序appid')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.mpaccountAppid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="wxaccountMchid"
                                                :label="$t('pageKey909', '支付商户ID')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.mpaccountMchid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="wxaccountMchkey"
                                                :label="$t('pageKey910', '支付商户key')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.mpaccountMchkey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="certSerialNo"
                                                :label="$t('pageKey911', '证书序列号')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.certSerialNo"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="subMpaccountMchid"
                                                :label="$t('pageKey913', '子支付商户ID')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.subMpaccountMchid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="withdrawWxaccountMchid"
                                                :label="$t('pageKey914', '提现支付商户ID')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.withdrawWxaccountMchid"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="withdrawWxaccountMchkey"
                                                :label="$t('pageKey915', '提现支付商户key')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.withdrawWxaccountMchkey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isMergePay"
                                                :label="$t('pageKey916', '是否合单支付')"
                                                label-width="11em"
                                            >
                                                <el-switch
                                                    v-model="configItem.isMergePay"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageRate"
                                                :label="$t('pageKey663', '手续费')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageCostRate"
                                                :label="$t('pageKey917', '手续费成本')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageCostRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="exchangeRate"
                                                :label="$t('pageKey918', '汇率')"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="6"
                                                    v-model.number="configItem.exchangeRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                :label="$t('pageKey919', '渠道商手续费')"
                                                label-width="11em"
                                            >
                                                <div class="ma-b">
                                                    <el-button @click="onAdd(configItem)">
                                                        {{ $t('pageKey15', '新增') }}
                                                    </el-button>
                                                </div>
                                                <el-table
                                                    stripe
                                                    border
                                                    size="small"
                                                    :data="configItem.externalPoundageAmountList"
                                                >
                                                    <el-table-column :label="$t('pageKey920', '付款渠道')">
                                                        <template slot-scope="scope">
                                                            <el-input
                                                                v-model="scope.row.paymentChannel"
                                                                :maxlength="20"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column :label="$t('pageKey921', '手续费比例')+'(%)'">
                                                        <template slot-scope="scope">
                                                            <el-input-number
                                                                controls-position="right"
                                                                :min="0"
                                                                :precision="2"
                                                                v-model.number="scope.row.poundageRate"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        :label="$t('pageKey13', '操作')"
                                                        width="60"
                                                    >
                                                        <template slot-scope="scope">
                                                            <el-button
                                                                size="small"
                                                                type="text"
                                                                @click="onDelete(scope.$index, configItem.externalPoundageAmountList)"
                                                            >
                                                                <span>{{ $t('pageKey17', '删除') }}</span>
                                                            </el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-form-item>
                                            <el-form-item
                                                prop="isCustomsClearance"
                                                :label="$t('pageKey922', '是否海关报送')"
                                                label-width="11em"
                                            >
                                                <el-switch
                                                    v-model="configItem.isCustomsClearance"
                                                />
                                            </el-form-item>
                                            <template
                                                v-if="configItem.isCustomsClearance">
                                                <el-form-item
                                                    prop="customsWxaccountAppid"
                                                    :label="$t('pageKey923', '报关公众号appid')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.customsWxaccountAppid"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="customsWxaccountMchid"
                                                    :label="$t('pageKey924', '报关商户ID')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.customsWxaccountMchid"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="customsWxaccountMchkey"
                                                    :label="$t('pageKey925', '报关商户key')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.customsWxaccountMchkey"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="customsPlace"
                                                    :label="$t('pageKey926', '海关编号')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.customsPlace"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="merchantCustomsName"
                                                    :label="$t('pageKey927', '商户海关备案名称')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.merchantCustomsName"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="merchantCustomsCode"
                                                    :label="$t('pageKey928', '商户海关备案编号')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.merchantCustomsCode"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                            </template>
                                            <el-form-item
                                                prop="isProfitSharing"
                                                :label="$t('pageKey929', '是否分账')"
                                                label-width="11em"
                                            >
                                                <el-switch
                                                    v-model="configItem.isProfitSharing"
                                                />
                                            </el-form-item>
                                            <template
                                                v-if="configItem.isProfitSharing">
                                                <el-form-item
                                                    :label="$t('pageKey930', '分账节点')"
                                                    label-width="11em"
                                                >
                                                    <el-select
                                                        v-model="configItem.profitSharingNodeType"
                                                        :placeholder="$t('pageKey101', '请选择')"
                                                        style="width: 100px"
                                                    >
                                                        <el-option
                                                            :label="$t('pageKey931', '订单下单')"
                                                            :value="1"
                                                        />
                                                        <el-option
                                                            :label="$t('pageKey932', '订单完成')"
                                                            :value="2"
                                                        />
                                                    </el-select>
                                                    <el-input-number
                                                        controls-position="right"
                                                        :min="0"
                                                        :precision="0"
                                                        v-model.number="configItem.profitSharingNodeDate"
                                                    />
                                                    {{ $t('pageKey933', '天后') }}
                                                </el-form-item>
                                                <el-form-item
                                                    :label="$t('pageKey934', '分账配置')"
                                                    label-width="11em"
                                                >
                                                    <div class="ma-b">
                                                        <el-button @click="onAdd1(configItem)">
                                                            {{ $t('pageKey15', '新增') }}
                                                        </el-button>
                                                    </div>
                                                    <el-table
                                                        stripe
                                                        border
                                                        size="small"
                                                        :data="configItem.profitSharingConfig"
                                                    >
                                                        <el-table-column
                                                            :label="$t('pageKey935', '是否主账号')"
                                                            width="90">
                                                            <template slot-scope="scope">
                                                                <el-switch
                                                                    v-model="scope.row.isMainAccount"
                                                                />
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column
                                                            :label="$t('pageKey936', '接收方类型')"
                                                            width="140">
                                                            <template slot-scope="scope">
                                                                <el-select
                                                                    v-model="scope.row.type"
                                                                    :placeholder="$t('pageKey101', '请选择')"
                                                                    style="width: 120px"
                                                                >
                                                                    <el-option
                                                                        :label="$t('pageKey937', '商户号')"
                                                                        value="MERCHANT_ID"
                                                                    />
                                                                    <el-option
                                                                        :label="$t('pageKey938', '个人')"
                                                                        value="PERSONAL_OPENID"
                                                                    />
                                                                </el-select>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column :label="$t('pageKey939', '接收方信息')">
                                                            <template slot-scope="scope">
                                                                <div>
                                                                    <span v-if="scope.row.type == 'MERCHANT_ID'">{{ $t('pageKey937', '商户号') }}：</span>
                                                                    <span v-else>{{ $t('pageKey938', '个人') }}openid：</span>
                                                                    <el-input
                                                                        v-model="scope.row.account"
                                                                        :maxlength="64"
                                                                        style="width: 300px"
                                                                    />
                                                                </div>
                                                                <div
                                                                    class="ma-t">
                                                                    <span v-if="scope.row.type == 'MERCHANT_ID'">{{ $t('pageKey940', '商户全称') }}：</span>
                                                                    <span v-else>{{ $t('pageKey941', '个人姓名') }}：</span>
                                                                    <el-input
                                                                        v-model="scope.row.name"
                                                                        :maxlength="64"
                                                                        style="width: 300px"
                                                                    />
                                                                </div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column
                                                            :label="$t('pageKey942', '分账比例')+'(%)'"
                                                            width="150">
                                                            <template slot-scope="scope">
                                                                <el-input-number
                                                                    controls-position="right"
                                                                    :min="0"
                                                                    :max="100"
                                                                    :precision="2"
                                                                    v-model.number="scope.row.rate"
                                                                />
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column
                                                            :label="$t('pageKey13', '操作')"
                                                            width="60"
                                                        >
                                                            <template slot-scope="scope">
                                                                <el-button
                                                                    size="small"
                                                                    type="text"
                                                                    @click="onDelete1(scope.$index, configItem.profitSharingConfig)"
                                                                >
                                                                    <span>{{ $t('pageKey17', '删除') }}</span>
                                                                </el-button>
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                </el-form-item>
                                            </template>
                                            <el-form-item
                                                prop="isFundExit"
                                                :label="$t('pageKey943', '是否资金出境')"
                                                label-width="11em"
                                            >
                                                <el-switch
                                                    v-model="configItem.isFundExit"
                                                />
                                            </el-form-item>
                                            <template
                                                v-if="configItem.isFundExit">
                                                <el-form-item
                                                    prop="fundExitRate"
                                                    :label="$t('pageKey944', '出境比例')+'（%）'"
                                                    label-width="11em"
                                                >
                                                    <el-input-number
                                                        controls-position="right"
                                                        :min="0"
                                                        :precision="2"
                                                        v-model.number="configItem.fundExitConfig.fundExitRate"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="foreignCurrency"
                                                    :label="$t('pageKey945', '境外收款币种')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.fundExitConfig.foreignCurrency"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="overseaBusinessName"
                                                    :label="$t('pageKey946', '境外卖家经营主体名称')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.fundExitConfig.overseaBusinessName"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="overseaShopName"
                                                    :label="$t('pageKey947', '境外卖家店铺名称')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.fundExitConfig.overseaShopName"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="sellerId"
                                                    :label="$t('pageKey948', '卖家ID')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.fundExitConfig.sellerId"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                                <el-form-item
                                                    prop="payeeId"
                                                    :label="$t('pageKey949', '收款人识别号')"
                                                    label-width="11em"
                                                >
                                                    <el-input
                                                        v-model="configItem.fundExitConfig.payeeId"
                                                        auto-complete="off"
                                                    />
                                                </el-form-item>
                                            </template>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                                <el-col
                                    :span="12"
                                    v-if="item.paymentType === 32768"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="addTab(item)"
                                        class="ma-b"
                                        v-if="multipleConfig"
                                    >
                                        {{ $t('pageKey900', '添加配置') }}
                                    </el-button>
                                    <el-tabs
                                        v-model="item.editableTabsValue"
                                        type="card"
                                        :closable="multipleConfig"
                                        @tab-remove="($event)=>{removeTab($event,item)}"
                                    >
                                        <el-tab-pane
                                            :key="index"
                                            v-for="(configItem, index) in item.paymentConfig"
                                            :label="multipleConfig ? $t('pageKey901', '配置') + (index + 1) :$t('pageKey902', '支付配置')"
                                            :name="index"
                                        >
                                            <el-form-item
                                                prop="ratio"
                                                :label="$t('pageKey903', '权重')"
                                                label-width="11em"
                                                v-if="multipleConfig"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="configItem.ratio"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isAvailable"
                                                :label="$t('pageKey12', '是否启用')"
                                                label-width="11em"
                                                v-if="!!storeId"
                                            >
                                                <el-switch
                                                    v-model="configItem.isAvailable"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="airwallexClientId"
                                                label="airwallexClientId"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.airwallexClientId"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="airwallexApiKey"
                                                label="airwallexApiKey"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.airwallexApiKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="airwallexSecretKey"
                                                label="airwallexSecretKey"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.airwallexSecretKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageRate"
                                                :label="$t('pageKey663', '手续费')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageCostRate"
                                                :label="$t('pageKey917', '手续费成本')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageCostRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="exchangeRate"
                                                :label="$t('pageKey918', '汇率')"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="6"
                                                    v-model.number="configItem.exchangeRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                :label="$t('pageKey919', '渠道商手续费')"
                                                label-width="11em"
                                            >
                                                <div class="ma-b">
                                                    <el-button @click="onAdd(configItem)">
                                                        {{ $t('pageKey15', '新增') }}
                                                    </el-button>
                                                </div>
                                                <el-table
                                                    stripe
                                                    border
                                                    size="small"
                                                    :data="configItem.externalPoundageAmountList"
                                                >
                                                    <el-table-column :label="$t('pageKey920', '付款渠道')">
                                                        <template slot-scope="scope">
                                                            <el-input
                                                                v-model="scope.row.paymentChannel"
                                                                :maxlength="20"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column :label="$t('pageKey921', '手续费比例')+'(%)'">
                                                        <template slot-scope="scope">
                                                            <el-input-number
                                                                controls-position="right"
                                                                :min="0"
                                                                :precision="2"
                                                                v-model.number="scope.row.poundageRate"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        :label="$t('pageKey13', '操作')"
                                                        width="60"
                                                    >
                                                        <template slot-scope="scope">
                                                            <el-button
                                                                size="small"
                                                                type="text"
                                                                @click="onDelete(scope.$index, configItem.externalPoundageAmountList)"
                                                            >
                                                                <span>{{ $t('pageKey17', '删除') }}</span>
                                                            </el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-form-item>
                                            <el-form-item
                                                prop="isCustomsClearance"
                                                :label="$t('pageKey922', '是否海关报送')"
                                                label-width="11em"
                                            >
                                                <el-switch
                                                    v-model="configItem.isCustomsClearance"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="customsPlace"
                                                :label="$t('pageKey926', '海关编号')"
                                                label-width="11em"
                                                v-if="configItem.isCustomsClearance"
                                            >
                                                <el-input
                                                    v-model="configItem.customsPlace"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="merchantCustomsName"
                                                :label="$t('pageKey927', '商户海关备案名称')"
                                                label-width="11em"
                                                v-if="configItem.isCustomsClearance"
                                            >
                                                <el-input
                                                    v-model="configItem.merchantCustomsName"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="merchantCustomsCode"
                                                :label="$t('pageKey928', '商户海关备案编号')"
                                                label-width="11em"
                                                v-if="configItem.isCustomsClearance"
                                            >
                                                <el-input
                                                    v-model="configItem.merchantCustomsCode"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                                <el-col
                                    :span="12"
                                    v-if="item.paymentType === 65536"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="addTab(item)"
                                        class="ma-b"
                                        v-if="multipleConfig"
                                    >
                                        {{ $t('pageKey900', '添加配置') }}
                                    </el-button>
                                    <el-tabs
                                        v-model="item.editableTabsValue"
                                        type="card"
                                        :closable="multipleConfig"
                                        @tab-remove="($event)=>{removeTab($event,item)}"
                                    >
                                        <el-tab-pane
                                            :key="index"
                                            v-for="(configItem, index) in item.paymentConfig"
                                            :label="multipleConfig ? $t('pageKey901', '配置') + (index + 1) :$t('pageKey902', '支付配置')"
                                            :name="index"
                                        >
                                            <el-form-item
                                                prop="ratio"
                                                :label="$t('pageKey903', '权重')"
                                                label-width="11em"
                                                v-if="multipleConfig"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="configItem.ratio"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isAvailable"
                                                :label="$t('pageKey12', '是否启用')"
                                                label-width="11em"
                                                v-if="!!storeId"
                                            >
                                                <el-switch
                                                    v-model="configItem.isAvailable"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="privateKey"
                                                label="privateKey"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.privateKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="publicKey"
                                                label="publicKey"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.publicKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="currency"
                                                :label="$t('pageKey959', '币种')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.currency"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageRate"
                                                :label="$t('pageKey663', '手续费')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageCostRate"
                                                :label="$t('pageKey917', '手续费成本')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageCostRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="exchangeRate"
                                                :label="$t('pageKey918', '汇率')"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="6"
                                                    v-model.number="configItem.exchangeRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                :label="$t('pageKey919', '渠道商手续费')"
                                                label-width="11em"
                                            >
                                                <div class="ma-b">
                                                    <el-button @click="onAdd(configItem)">
                                                        {{ $t('pageKey15', '新增') }}
                                                    </el-button>
                                                </div>
                                                <el-table
                                                    stripe
                                                    border
                                                    size="small"
                                                    :data="configItem.externalPoundageAmountList"
                                                >
                                                    <el-table-column :label="$t('pageKey920', '付款渠道')">
                                                        <template slot-scope="scope">
                                                            <el-input
                                                                v-model="scope.row.paymentChannel"
                                                                :maxlength="20"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column :label="$t('pageKey921', '手续费比例')+'(%)'">
                                                        <template slot-scope="scope">
                                                            <el-input-number
                                                                controls-position="right"
                                                                :min="0"
                                                                :precision="2"
                                                                v-model.number="scope.row.poundageRate"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        :label="$t('pageKey13', '操作')"
                                                        width="60"
                                                    >
                                                        <template slot-scope="scope">
                                                            <el-button
                                                                size="small"
                                                                type="text"
                                                                @click="onDelete(scope.$index, configItem.externalPoundageAmountList)"
                                                            >
                                                                <span>{{ $t('pageKey17', '删除') }}</span>
                                                            </el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-form-item>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                                <el-col
                                    :span="12"
                                    v-if="item.paymentType === 131072"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="addTab(item)"
                                        class="ma-b"
                                        v-if="multipleConfig"
                                    >
                                        {{ $t('pageKey900', '添加配置') }}
                                    </el-button>
                                    <el-tabs
                                        v-model="item.editableTabsValue"
                                        type="card"
                                        :closable="multipleConfig"
                                        @tab-remove="($event)=>{removeTab($event,item)}"
                                    >
                                        <el-tab-pane
                                            :key="index"
                                            v-for="(configItem, index) in item.paymentConfig"
                                            :label="multipleConfig ? $t('pageKey901', '配置') + (index + 1) :$t('pageKey902', '支付配置')"
                                            :name="index"
                                        >
                                            <el-form-item
                                                prop="ratio"
                                                :label="$t('pageKey903', '权重')"
                                                label-width="11em"
                                                v-if="multipleConfig"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="configItem.ratio"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isAvailable"
                                                :label="$t('pageKey12', '是否启用')"
                                                label-width="11em"
                                                v-if="!!storeId"
                                            >
                                                <el-switch
                                                    v-model="configItem.isAvailable"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="merchantNO"
                                                label="商户号"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.merchantNO"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="weixinJSAPISubAppId"
                                                label="公众号appId"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.weixinJSAPISubAppId"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="privateKey"
                                                label="privateKey"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    type="textarea"
                                                    v-model="configItem.privateKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="currency"
                                                :label="$t('pageKey959', '币种')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.currency"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="settleCurrency"
                                                label="结算币种"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.settleCurrency"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageRate"
                                                :label="$t('pageKey663', '手续费')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageCostRate"
                                                :label="$t('pageKey917', '手续费成本')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageCostRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="exchangeRate"
                                                :label="$t('pageKey918', '汇率')"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="6"
                                                    v-model.number="configItem.exchangeRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                :label="$t('pageKey919', '渠道商手续费')"
                                                label-width="11em"
                                            >
                                                <div class="ma-b">
                                                    <el-button @click="onAdd(configItem)">
                                                        {{ $t('pageKey15', '新增') }}
                                                    </el-button>
                                                </div>
                                                <el-table
                                                    stripe
                                                    border
                                                    size="small"
                                                    :data="configItem.externalPoundageAmountList"
                                                >
                                                    <el-table-column :label="$t('pageKey920', '付款渠道')">
                                                        <template slot-scope="scope">
                                                            <el-input
                                                                v-model="scope.row.paymentChannel"
                                                                :maxlength="20"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column :label="$t('pageKey921', '手续费比例')+'(%)'">
                                                        <template slot-scope="scope">
                                                            <el-input-number
                                                                controls-position="right"
                                                                :min="0"
                                                                :precision="2"
                                                                v-model.number="scope.row.poundageRate"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        :label="$t('pageKey13', '操作')"
                                                        width="60"
                                                    >
                                                        <template slot-scope="scope">
                                                            <el-button
                                                                size="small"
                                                                type="text"
                                                                @click="onDelete(scope.$index, configItem.externalPoundageAmountList)"
                                                            >
                                                                <span>{{ $t('pageKey17', '删除') }}</span>
                                                            </el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-form-item>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                                <el-col
                                    :span="12"
                                    v-if="item.paymentType === 262144"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="addTab(item)"
                                        class="ma-b"
                                        v-if="multipleConfig"
                                    >
                                        {{ $t('pageKey900', '添加配置') }}
                                    </el-button>
                                    <el-tabs
                                        v-model="item.editableTabsValue"
                                        type="card"
                                        :closable="multipleConfig"
                                        @tab-remove="($event)=>{removeTab($event,item)}"
                                    >
                                        <el-tab-pane
                                            :key="index"
                                            v-for="(configItem, index) in item.paymentConfig"
                                            :label="multipleConfig ? $t('pageKey901', '配置') + (index + 1) :$t('pageKey902', '支付配置')"
                                            :name="index"
                                        >
                                            <el-form-item
                                                prop="ratio"
                                                :label="$t('pageKey903', '权重')"
                                                label-width="11em"
                                                v-if="multipleConfig"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="configItem.ratio"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isAvailable"
                                                :label="$t('pageKey12', '是否启用')"
                                                label-width="11em"
                                                v-if="!!storeId"
                                            >
                                                <el-switch
                                                    v-model="configItem.isAvailable"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="merchantNO"
                                                label="商户号"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.merchantNO"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="privateKey"
                                                label="privateKey"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    type="textarea"
                                                    v-model="configItem.privateKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="publicKey"
                                                label="publicKey"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    type="textarea"
                                                    v-model="configItem.publicKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="currency"
                                                :label="$t('pageKey959', '币种')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.currency"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="settleCurrency"
                                                label="结算币种"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.settleCurrency"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageRate"
                                                :label="$t('pageKey663', '手续费')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageCostRate"
                                                :label="$t('pageKey917', '手续费成本')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageCostRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="exchangeRate"
                                                :label="$t('pageKey918', '汇率')"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="6"
                                                    v-model.number="configItem.exchangeRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                :label="$t('pageKey919', '渠道商手续费')"
                                                label-width="11em"
                                            >
                                                <div class="ma-b">
                                                    <el-button @click="onAdd(configItem)">
                                                        {{ $t('pageKey15', '新增') }}
                                                    </el-button>
                                                </div>
                                                <el-table
                                                    stripe
                                                    border
                                                    size="small"
                                                    :data="configItem.externalPoundageAmountList"
                                                >
                                                    <el-table-column :label="$t('pageKey920', '付款渠道')">
                                                        <template slot-scope="scope">
                                                            <el-input
                                                                v-model="scope.row.paymentChannel"
                                                                :maxlength="20"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column :label="$t('pageKey921', '手续费比例')+'(%)'">
                                                        <template slot-scope="scope">
                                                            <el-input-number
                                                                controls-position="right"
                                                                :min="0"
                                                                :precision="2"
                                                                v-model.number="scope.row.poundageRate"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        :label="$t('pageKey13', '操作')"
                                                        width="60"
                                                    >
                                                        <template slot-scope="scope">
                                                            <el-button
                                                                size="small"
                                                                type="text"
                                                                @click="onDelete(scope.$index, configItem.externalPoundageAmountList)"
                                                            >
                                                                <span>{{ $t('pageKey17', '删除') }}</span>
                                                            </el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-form-item>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                                <el-col
                                    :span="12"
                                    v-if="item.paymentType === 524288"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="addTab(item)"
                                        class="ma-b"
                                        v-if="multipleConfig"
                                    >
                                        {{ $t('pageKey900', '添加配置') }}
                                    </el-button>
                                    <el-tabs
                                        v-model="item.editableTabsValue"
                                        type="card"
                                        :closable="multipleConfig"
                                        @tab-remove="($event)=>{removeTab($event,item)}"
                                    >
                                        <el-tab-pane
                                            :key="index"
                                            v-for="(configItem, index) in item.paymentConfig"
                                            :label="multipleConfig ? $t('pageKey901', '配置') + (index + 1) :$t('pageKey902', '支付配置')"
                                            :name="index"
                                        >
                                            <el-form-item
                                                prop="ratio"
                                                :label="$t('pageKey903', '权重')"
                                                label-width="11em"
                                                v-if="multipleConfig"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="configItem.ratio"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="isAvailable"
                                                :label="$t('pageKey12', '是否启用')"
                                                label-width="11em"
                                                v-if="!!storeId"
                                            >
                                                <el-switch
                                                    v-model="configItem.isAvailable"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="clientId"
                                                label="clientId"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.clientId"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="alipayPublicKey"
                                                label="alipayPublicKey"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.alipayPublicKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="merchantPrivateKey"
                                                label="merchantPrivateKey"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.merchantPrivateKey"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="currency"
                                                :label="$t('pageKey959', '币种')"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.currency"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="settleCurrency"
                                                label="结算币种"
                                                label-width="11em"
                                            >
                                                <el-input
                                                    v-model="configItem.settleCurrency"
                                                    auto-complete="off"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageRate"
                                                :label="$t('pageKey663', '手续费')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="paymentPoundageCostRate"
                                                :label="$t('pageKey917', '手续费成本')+'(%)'"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="configItem.paymentPoundageCostRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                prop="exchangeRate"
                                                :label="$t('pageKey918', '汇率')"
                                                label-width="11em"
                                            >
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="6"
                                                    v-model.number="configItem.exchangeRate"
                                                />
                                            </el-form-item>
                                            <el-form-item
                                                :label="$t('pageKey919', '渠道商手续费')"
                                                label-width="11em"
                                            >
                                                <div class="ma-b">
                                                    <el-button @click="onAdd(configItem)">
                                                        {{ $t('pageKey15', '新增') }}
                                                    </el-button>
                                                </div>
                                                <el-table
                                                    stripe
                                                    border
                                                    size="small"
                                                    :data="configItem.externalPoundageAmountList"
                                                >
                                                    <el-table-column :label="$t('pageKey920', '付款渠道')">
                                                        <template slot-scope="scope">
                                                            <el-input
                                                                v-model="scope.row.paymentChannel"
                                                                :maxlength="20"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column :label="$t('pageKey921', '手续费比例')+'(%)'">
                                                        <template slot-scope="scope">
                                                            <el-input-number
                                                                controls-position="right"
                                                                :min="0"
                                                                :precision="2"
                                                                v-model.number="scope.row.poundageRate"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        :label="$t('pageKey13', '操作')"
                                                        width="60"
                                                    >
                                                        <template slot-scope="scope">
                                                            <el-button
                                                                size="small"
                                                                type="text"
                                                                @click="onDelete(scope.$index, configItem.externalPoundageAmountList)"
                                                            >
                                                                <span>{{ $t('pageKey17', '删除') }}</span>
                                                            </el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-form-item>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                            </el-tab-pane>
                        </el-tabs>
                    </el-form-item>
                    <el-form-item
                        prop="isRandomPaymentType"
                        label="是否随机付款方式"
                        label-width="11em"
                    >
                        <el-switch
                            v-model="formModel.isRandomPaymentType"
                        />
                        <span
                            v-if="formModel.isRandomPaymentType"
                            v-for="item in paymentTypeRandomList"
                            :key="item.paymentType">
                            <el-checkbox
                                v-model="item.isRandom"
                                class="ma-l">
                                {{ item.paymentTypeName }}权重
                                <el-input-number
                                    controls-position="right"
                                    :min="0"
                                    v-model.number="item.ratio"
                                />
                            </el-checkbox>
                        </span>
                    </el-form-item>
                </el-form>
            </el-col>
            <div class="pa-t">
                <el-button
                    size="small"
                    type="primary"
                    @click="onConfirm()"
                >
                    {{ $t('pageKey73', '保 存') }}
                </el-button>
                <el-button
                    size="small"
                    @click="$router.back()"
                >
                    {{ $t('pageKey126', '返 回') }}
                </el-button>
            </div>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ShopPaymentConfig',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            shopPaymentConfig: [],
            tabName: '',
            formModel: {
                id: '',
                shopId: '',
                templateName: '',
                storeIds: '',
                goodsType: '',
                shipMethod: '',
                goodsSendModes: [],
                orderTypes: [],
                paymentType: '',
                isAvailable: true,
                isDefault: true,
                isRandomPaymentType: false,
            },
            // 表单校验规则
            formRules: {
                shopId: {
                    required: true,
                    message: this.$t('pageKey974', '请选择商户'),
                },
                templateName: {
                    required: true,
                    message: this.$t('pageKey169', '请输入模板名称'),
                    trigger: 'blur',
                },
                paymentType: {
                    required: true,
                    message: this.$t('pageKey432', '请选择付款方式'),
                },
                isAvailable: {
                    required: true,
                    message: this.$t('pageKey431', '请选择是否可用'),
                },
                isDefault: {
                    required: true,
                    message: this.$t('pageKey975', '请选择是否默认'),
                },
            },
            goodsTypeList: [],
            shipMethodList: [],
            goodsSendModeList: [],
            orderTypeList: [],
            paymentTypeList: [],
            multipleConfig: false,
            paymentTypeRandomList: [],
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                const otherConfig = {
                    isRandomPaymentType: this.formModel.isRandomPaymentType,
                    paymentTypeRandomList: this.paymentTypeRandomList,
                };
                this.$api.Sp.ShopPaymentTemplate.save({
                    ...this.formModel,
                    // storeIds: this.formModel.storeIds.join(','),
                    goodsSendModes: this.formModel.goodsSendModes.join(','),
                    orderTypes: this.formModel.orderTypes.join(','),
                    detailJson: JSON.stringify(this.shopPaymentConfig),
                    otherConfig: JSON.stringify(otherConfig),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        initShopPaymentConfig() {
            /* this.$api.Sp.ShopPaymentConfig.data({ shopId: this.shopId, storeId: this.storeId }).then(json => {
                const res = json.data.data;
                res.forEach(item => {
                    const { paymentConfig } = item;
                    if (paymentConfig.length === 0) {
                        paymentConfig.push({
                            externalPoundageAmountList: [],
                            profitSharingConfig: [],
                            profitSharingNodeType: 1,
                            profitSharingNodeDate: 0,
                        });
                    } else {
                        paymentConfig.forEach(item1 => {
                            if (!item1.externalPoundageAmountList) {
                                item1.externalPoundageAmountList = [];
                            }
                            if (!item1.profitSharingConfig) {
                                item1.profitSharingConfig = [];
                            }
                            if (!item1.profitSharingNodeType) item1.profitSharingNodeType = 1;
                            if (!item1.profitSharingNodeDate) item1.profitSharingNodeDate = 0;
                        });
                    }
                });
                this.tabName = res[0].paymentTypeName;
                this.shopPaymentConfig = res;
            }); */
            this.$api.Sp.ShopPaymentTemplate.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.changeShop(res.shopPaymentTemplate.shopId);
                /* if (res.shopPaymentTemplate.storeIds) {
                    res.shopPaymentTemplate.storeIds = res.shopPaymentTemplate.storeIds
                        .split(',')
                        .reduce((prev, next) => {
                            prev.push(+next);
                            return prev;
                        }, []);
                } else {
                    res.shopPaymentTemplate.storeIds = [];
                } */
                if (res.shopPaymentTemplate.goodsSendModes) {
                    res.shopPaymentTemplate.goodsSendModes = res.shopPaymentTemplate.goodsSendModes.split(',');
                } else {
                    res.shopPaymentTemplate.goodsSendModes = [];
                }
                if (res.shopPaymentTemplate.orderTypes) {
                    res.shopPaymentTemplate.orderTypes = res.shopPaymentTemplate.orderTypes.split(',');
                } else {
                    res.shopPaymentTemplate.orderTypes = [];
                }
                res.shopPaymentConfigList.forEach(item1 => [
                    item1.paymentConfig.forEach(item2 => {
                        if (!item2.fundExitConfig) item2.fundExitConfig = {};
                    }),
                ]);
                this.shopPaymentConfig = res.shopPaymentConfigList;
                this.$utils.formModelMerge(this.formModel, res.shopPaymentTemplate);
                const otherConfig = JSON.parse(res.shopPaymentTemplate.otherConfig || '{}');
                this.formModel.isRandomPaymentType = otherConfig.isRandomPaymentType;
                this.paymentTypeRandomList = otherConfig.paymentTypeRandomList || [];
            });
        },
        onAdd(item) {
            item.externalPoundageAmountList.push({
                paymentChannel: '',
                poundageRate: 0,
            });
        },
        onDelete(index, row) {
            row.splice(index, 1);
        },
        onAdd1(item) {
            item.profitSharingConfig.push({
                type: 'MERCHANT_ID',
            });
        },
        onDelete1(index, row) {
            row.splice(index, 1);
        },
        addTab(item) {
            item.paymentConfig.push({});
        },
        removeTab(targetName, item) {
            if (item.paymentConfig.length === 1) {
                this.$message({
                    message: this.$t('pageKey976', '配置至少存在一个'),
                    type: 'error',
                });
                return;
            }
            item.paymentConfig.splice(targetName, 1);
        },
        changeShop(shopId) {
            if (!shopId) {
                this.formModel.storeIds = [];
                this.formModel.goodsType = '';
                this.formModel.shipMethod = '';
                this.formModel.goodsSendModes = [];
                this.formModel.orderTypes = [];
                this.formModel.paymentType = '';
            }
            this.$api.Sp.Shop.getShopAvailableGoodsType({ shopId: shopId || this.formModel.shopId }).then(json => {
                this.goodsTypeList = json.data.data;
            });
            this.$api.Sp.Shop.getShopPaymentTypeList({ shopId: shopId || this.formModel.shopId }).then(json => {
                this.paymentTypeList = json.data.data;
            });
            this.$api.Sp.Shop.getShopGoodsSendModeList({ shopId: shopId || this.formModel.shopId }).then(json => {
                this.goodsSendModeList = json.data.data;
            });
            this.$api.Rs.Dic.entrys({ dicCode: 'orderType' }).then(json => {
                this.orderTypeList = json.data.data;
            });
            this.$api.Sp.Shop.getShopShipMethodList({ shopId: shopId || this.formModel.shopId }).then(json => {
                this.shipMethodList = json.data.data;
            });
        },
    },
    watch: {
        'formModel.paymentType': {
            handler() {
                const list = [];
                const paymentTypeRandomList = [];
                if (this.paymentTypeList.length === 0) return;
                this.paymentTypeList.forEach(item => {
                    if ((item.id & this.formModel.paymentType) !== 0) {
                        let isAdd = false;
                        this.shopPaymentConfig.forEach(item1 => {
                            if (item1.paymentType === item.id) {
                                list.push(item1);
                                isAdd = true;
                            }
                        });
                        if (!isAdd) {
                            const newItem = {
                                paymentType: item.id,
                                paymentTypeName: item.name,
                            };
                            this.$set(newItem, 'paymentConfig', [
                                {
                                    externalPoundageAmountList: [],
                                    profitSharingConfig: [],
                                    profitSharingNodeType: 1,
                                    profitSharingNodeDate: 0,
                                    fundExitConfig: {},
                                },
                            ]);
                            list.push(newItem);
                        }
                    }
                    if ((item.id & this.formModel.paymentType) !== 0) {
                        let isAdd = false;
                        this.paymentTypeRandomList.forEach(item1 => {
                            if (item1.paymentType === item.id) {
                                paymentTypeRandomList.push(item1);
                                isAdd = true;
                            }
                        });
                        if (!isAdd) {
                            const newItem = {
                                paymentType: item.id,
                                paymentTypeName: item.name,
                                ratio: '',
                            };
                            paymentTypeRandomList.push(newItem);
                        }
                    }
                });
                this.shopPaymentConfig = list;
                this.paymentTypeRandomList = paymentTypeRandomList;
            },
            immediate: true,
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.initShopPaymentConfig();
        } else {
            this.changeShop();
        }
    },
};
</script>

<style lang="scss">
</style>
